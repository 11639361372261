<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam grade point for different course</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Grade point
                </div>
              </div>

            </div>
          </div>
          <v-card>
            <v-card-text>
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="4">
                    <v-select
                        class="form-control"
                        v-model="search.grade_id"
                        @change="getCourse()"
                        v-on:keyup.enter="searchGradePoint()"
                        dense
                        item-text="title"
                        item-value="id"
                        :items="grades"
                        label="Grade/semester"
                        outlined
                        clearable
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                        class="form-control"
                        v-model="search.course_id"
                        @change="searchGradePoint()"
                        item-text="title"
                        item-value="id"
                        :items="courses"
                        label="Course"
                        outlined
                        clearable
                        dense
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn :loading="loading" @click.prevent="searchGradePoint()"
                           class="btn btn-primary w-35 float-right" dark>
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <v-container v-if="point && point.has_different_course_grade_point && search.grade_id && search.course_id">
                  <div class="row">
                    <div class="col-12">
                      <h4>Grading calculation value & logic</h4>
                      <v-btn @click="addValue" class="mt-4 btn btn-primary float-right" style="color: #fff">
                        <i class="fas fa-plus"></i> Add new condition
                      </v-btn>
                    </div>
                  </div>
                  <div class="row mt-3" v-for="(value, index) of values" :key="index">
                    <div class="col-2">
                      <v-text-field outlined dense v-model="value.start_from" label="Mark lower limit"></v-text-field>
                      <span class="text-danger"
                            v-if="$v.values.$each[index].start_from.$error">This information is required</span>
                    </div>
                    <div class="col-2">
                      <v-text-field outlined dense v-model="value.end_to" label="Mark upper limit"></v-text-field>
                      <span class="text-danger"
                            v-if="$v.values.$each[index].end_to.$error">This information is required</span>
                    </div>
                    <div class="col-2" v-if="point && point.type == 'gpa'">
                      <v-text-field outlined dense v-model="value.grade" label="Grade"></v-text-field>
                      <span class="text-danger"
                            v-if="point && point.type == 'gpa' && $v.values.$each[index].grade.$error">This information is required</span>
                    </div>
                    <div class="col-2" v-if="point && point.type == 'gpa'">
                      <v-text-field outlined dense v-model="value.grade_point" label="Grade point"></v-text-field>
                      <span class="text-danger"
                            v-if="point && point.type == 'gpa' && $v.values.$each[index].grade_point.$error">This information is required</span>
                    </div>
                    <div class="col-2">
                      <v-text-field outlined dense v-model="value.division" label="Division"></v-text-field>
                      <span class="text-danger"
                            v-if="$v.values.$each[index].division.$error">This information is required</span>
                    </div>
                    <div class="col-1" v-if="point && point.grade_point_type=='course'">
                      <v-switch v-model="value.is_pass" :label="value.is_pass==1?'Pass':'Fail'">
                      </v-switch>
                    </div>
                    <div class="col-1">
                      <v-btn type="button" @click="removeValue(index, value.id)" fab small color="pink">
                        <v-icon color="white" small>
                          mdi-trash-can
                        </v-icon>
                      </v-btn>

                    </div>
                  </div>
              </v-container>
              <v-container v-else>
                <h6 class="text-center">Please select grade and course</h6>
              </v-container>
            </v-card-text>
            <v-card-actions v-if="point && point.has_different_course_grade_point && search.grade_id && search.course_id">
              <v-spacer></v-spacer>
              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="cancel">
                Cancel
              </v-btn>
              <v-btn
                  v-if="checkIsAccessible('grade-point-value', 'create') || checkIsAccessible('grade-point-value', 'edit')"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 @click="createAndUpdate"
                 :loading="isBusy"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>

        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import GradePointService from "@/core/services/level/program/grade-point/GradePointService";
import GradePointValueService from "@/core/services/level/program/grade-point/value/GradePointValueService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import CoursesService from "@/core/services/courses/CoursesService";

const grade = new GradeLevelService();
const coursesService = new CoursesService();
const pointService = new GradePointService();
const valueService = new GradePointValueService();

export default {
  name: "different-course-grade-point",
  data() {
    return {
      edit: false,
      isBusy: false,
      loading: false,
      grades: [],
      courses: [],
      values: [],
      point: {},
      search: {
        grade_id: '',
        course_id: ''
      },
    };
  },
  validations() {
    return {
      values: this.rule
    }
  },
  computed: {
    id(){
      return this.$route.params.id
    },
    rule() {
      let rule = {}
      if (this.point && this.point.type == 'gpa') {
        rule = {
          $each: {
            start_from: {required},
            end_to: {required},
            grade: {required},
            grade_point: {required},
            division: {required},
          }
        }
      } else {
        rule = {
          $each: {
            start_from: {required},
            end_to: {required},
            division: {required},
          }
        }
      }

      return rule;
    }
  },
  mounted() {
    this.getGrades();
    this.getGradePoint();
  },
  methods: {
    searchGradePoint() {
      this.values = [];
      this.getAllGradePointValues();
    },
    getGradePoint() {
      pointService.show(this.id).then(response => {
        this.point = response.data.point;
      });
    },
    getGrades() {
      grade.all().then(response => {
        this.grades = response.data.data;
      }).catch(() => {});
    },
    getCourse() {
      coursesService.getCourseByGrade(this.search.grade_id).then(response => {
        this.courses = response.data.courses;
      }).catch(() => {});
    },
    getAllGradePointValues() {
      valueService.paginate(this.id, this.search).then(response => {
        this.values = response.data.data;
      }).catch(() => {});
    },
    addValue() {
      this.values.push({
        grade_point_id: this.id,
        grade_id: this.search.grade_id,
        course_id: this.search.course_id,
        start_from: null,
        end_to: null,
        grade: null,
        grade_point: null,
        is_pass: 1,
        division: null,
        remarks: null,
      });
    },
    cancel() {
      this.reset();
      this.$tabs.close().then(() => {
        this.$tabs.open({
          name: "exams-grade-point"
        })
      });
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
          this.createAndUpdateValue();
      }
    },
    createAndUpdateValue() {
      valueService.createAndUpdate(this.id, this.values).then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Grade point added");
            this.dialog = false;
            this.getAllGradePointValues();
            this.$emit("refresh");
          }
      }).catch(() => {
        this.$snotify.error("Something went wrong");
      });
    },

    removeValue(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            if (id) {
              valueService
                  .delete(this.point.id, id)
                  .then(response => {
                    this.$snotify.success("Information deleted");
                  })
                  .catch(error => {
                    //console.log(error);
                  });
            }
            this.values.splice(index, 1);

          }
        }
      });
    },
  }
};
</script>
